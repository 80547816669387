.login-input .MuiOutlinedInput-root {
   height: 48px;
   border-radius: 12px;
}
.spinner {
   display: inline-block;
   width: 20px;
   height: 20px;
   border: 2px solid rgba(255, 255, 255, 0.3);
   border-radius: 50%;
   border-top-color: white;
   animation: spin 0.6s linear infinite;
}

@keyframes spin {
   to {
      transform: rotate(360deg);
   }
}